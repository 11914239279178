.footer {
	margin-top: auto;
	padding: 2em;
	background-color: #fff;
	margin-top: 1em;
}

.copyright {
	font-size: 0.8em;
	color: #002a48;
}
