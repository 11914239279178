.form {
  padding: 1rem;
  padding-top: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.sectionTitle {
  text-align: left;
  padding-top: 0;
}

.sectionPara {
  text-align: left;
  font-weight: bold;
}

.actionBtn {
  /* justify-self: start; */
  margin-top: 1rem !important;
  max-width: 250px !important;
  text-transform: capitalize !important;
  background-color: #002a48 !important;
  border-radius: 25px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  /* min-width: 80px !important; */
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

::backdrop {
  background-image: linear-gradient(
    45deg,
    magenta,
    rebeccapurple,
    dodgerblue,
    green
  );
  opacity: 0.75;
}
