.container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  justify-content: center;
  margin: 10px auto;
  border-radius: 5px;
}

.form {
  max-width: 500px;
  padding: 1em 2em 2em 2em;
  padding-top: 1em;
}

/* Paper {
  border-radius: 10px;
} */

.signup {
  padding-top: 1em;
}
