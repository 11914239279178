.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background: #fff;
  /* padding: 20px; */
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #002a48;
  /* max-width: 60rem; */

  /* margin: 20px; */
}

.value {
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  /* margin: 15px 0px; */
  margin: 0px;
}

.title,
.apps {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.apps {
  font-size: 1.1rem;
  color: slategrey;
}

.grid {
  display: flex;
  flex-direction: column;
  border: 1px solid #c2d52e;
  border-radius: 5px;
  padding: 1rem;
}

.grid:hover {
  border: 1px solid #002a48;
  background: #f2f2f2;
}

.section {
  margin-top: 0.7rem;
}

.subTitle {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 0px;
}
