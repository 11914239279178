.page {
	/* margin-top: 20px; */
	margin: 1rem;
	width: 100%;
	/* max-width: 720px; */
	max-width: 80rem;
	background-color: #fff;
	border-radius: 3px;
}

.cardShadow {
	border: 1px solid #f9f9f9;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.headerDisplay {
	font-size: 0.9rem;
	text-align: left;
}
