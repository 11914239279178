.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 80rem;
  background-color: #fff;
  border-radius: 3px;
  padding: 1rem;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

::backdrop {
  background-image: linear-gradient(
    45deg,
    magenta,
    rebeccapurple,
    dodgerblue,
    green
  );
  opacity: 0.75;
}
