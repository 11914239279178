.container {
  display: flex;
  flex-direction: column;
  /* min-width: 250px; */
  max-width: 450px;
  justify-content: center;
  margin: 10px auto;
  border-radius: 5px;
}

.form {
  min-width: 90%;
  padding: 0.2em 1em 2em;
  align-self: center;
  margin: 1em;
}

.signup {
  padding-top: 1em;
}

.google {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  border: 1px solid rgba(25, 118, 210, 0.5);
  padding: 5px 15px;
  width: 220px;
  margin: 0 auto;
  border-radius: 4px;
  /* margin-bottom: 10px; */
}

@media (max-width: 470px) {
  .container {
    max-width: 90%;
  }
}
