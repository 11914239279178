.container {
  border: 1px solid #002a48;
  max-width: 60rem;
  margin: 0 auto;
  /* background-color: #f2f2f2; */
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  width: 90%;
}

.buttonssection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.progress {
  display: flex;
  flex-direction: column;
}
