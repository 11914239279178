.container {
  background-color: #fefbe5;
  border: 1px solid #f9dd0d;
  padding: 1rem;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  text-align: left;
}

.txt {
  align-content: center;
}
