.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background: #fff;
  /* padding: 20px; */
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #002a48;
  max-width: 60rem;

  margin: 0 auto;
}

.section {
  padding: 1em;
  margin: 0.5em;
  margin-top: 1.5em;
}

.buttonssection {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 576px) {
  /* body {
    background-color: lightgreen;
  } */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: red;
}

hr {
  border: 1px solid #f9dd0d;
}
