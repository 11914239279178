.container-verticle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-horizontal {
  max-width: 30rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
}

.status-filter {
  margin: 30px auto;
  background-color: #00adbb;
  /* background-color: rgb(195, 213, 0); */
  max-width: 40rem;
  padding: 0.5rem;
  border-radius: 5px;
}

.status-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.status-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}

.status-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  /* color: var(--text-color); */
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}

.status-filter button:last-child {
  border: 0;
}

.status-filter button.active {
  color: #fff;
}
