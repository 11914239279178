@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Montserrat";
  /* background-color: #f2f2f2; */
  background-color: #00adbb;

  min-height: 100%;
}

footer {
  margin-top: auto;
  background-color: #00adbb;
}

.container {
  max-width: 60rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #002a48;
}

.flex-center {
  display: flex;
  justify-content: center;
}

/* style={{ display: "flex", justifyContent: "center" }} */

.container-decorator {
  margin: -1rem -1rem 1rem -1rem;
  height: 3rem;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: -1rem;
  background-image: linear-gradient(
    to bottom,
    #5ce1be,
    #53dcbf,
    #4ad8bf,
    #40d3bf,
    #37cebf,
    #2fcabf,
    #27c6bf,
    #1fc2bf,
    #16bdbe,
    #0db8be,
    #05b3bc,
    #01aebb
  );
}

.title-decorator {
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 1rem;
}

.title-left {
  text-align: left;
}

.paper {
  margin-bottom: 20px;
  padding: 1rem;
}
