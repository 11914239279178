.container {
  /* max-width: 920px; */
  min-height: 100vh;
  min-height: 100dvh;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  /* border: 1px solid red; */
  /* background-color: aqua; */
  /* min-height: 100vh; */
}

/* .footer {
  margin-top: auto;
  padding: 3em;
  background-color: #fff;
  margin-top: 2em;
} */

@media (min-width: 40rem) {
  .split {
    display: flex;
    gap: 2em;
  }
}
