.container {
}

ul {
  text-align: left;
  margin-left: 1rem;
}

label {
  margin-bottom: 1rem;
  padding: 1rem;
}

.error {
  color: red;
}

.dz {
  border: 2px dashed #002a48;
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  height: 100px;
}

p {
  padding-top: 0px;
  padding-bottom: 0px;
}
/* 
className="my-dropzone"
 */
