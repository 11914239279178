.container {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row {
  row-gap: 2;
  column-gap: 2;
  margin-left: 0.2rem;
}

.btn {
  margin-left: 10px;
}
